.card {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 40px 0 60px 0;
    gap: 20px;
    align-items: stretch;
  }
  
  .image {
    width: 770px;
    height: 540px;
    object-fit: cover;
    border-radius: 20px;
  }
  
  .details {
    // padding-top: 10px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-direction: column;
  }
  .title__group h3 {
    text-transform: uppercase;
    padding-top: 40px;
    font-size: 64px;
    font-weight: 400;
}
.title__group p {
  padding-top: 16px;
  padding-bottom: 40px;
  border-bottom: 1px solid #fff;
  font-size: 20px;
}

.icons_card {
  display: flex;
  gap: 15px;
}
  
  .features {
    font-size: 13px;
    list-style-type: none;
    padding: 10px 20px;
    display: flex;
    gap: 30px;
    border-radius: 20px;
    width: fit-content;
  }

  .card_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .card_flex button {
    border-radius: 30px;
    border: none;
    padding: 10px 20px;
    color: #fff;
  }


  @media (max-width: 678px) {
.card {
  flex-direction: column;
}
.card_flex {
  padding-top: 15px;
}
    .image {
      max-width: 390px;
      max-height: 260px;
    }
    .title__group h3 {
      font-size: 32px;
  }
  .features {
    font-size: 10px;
  }
  }

  @media (max-width: 400px) {
    .image {
      max-width: 350px;
    }
  }