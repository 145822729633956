@import 'variables';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
  background: $bg-primary;
  font-family: 'NAMU', sans-serif;
  font-weight: 400;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1rem;
  color: $text_icons-Primary;
  @media screen and (max-width: 767px) {
    cursor: none !important;
  }
}

.adapt__mob {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .adapt__mob {
    display: block !important;
  }
  .adapt__desc {
    display: none !important;
  }
}

.app {
  max-width: 1320px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    max-width: 737px;
    padding: 0 15px;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}
a,
button {
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    cursor: none !important;
  }
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'NAMU';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/NAMU-1850.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.loader__wrapper {
  height: calc(100vh - 80px);
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 50%;
  color: $brand-secondary-strong;
  animation: fill 1s ease-in infinite alternate;
}
.loader::before,
.loader::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 48px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
  left: auto;
  right: 48px;
  animation-duration: 1.1s;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 0 2px inset;
  }
  100% {
    box-shadow: 0 0 0 10px inset;
  }
}
