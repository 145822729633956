@import "variables";

@mixin Text ($line: normal, $size,) {
  font-size: $size;
  line-height: $line;
  font-weight: 400;
}

@mixin Title ($size) {
  color: $text_icons-Primary;
  font-size: $size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}