@import 'src/styles/mixin';
@import 'src/styles/variables';

.nav_mob {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  @media screen and (max-width: 767px) {
    margin-top: 30.33px;
  }

  .logo {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 767px) {
      width: 144.001px;
    }
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    color: $text_icons-Primary;
    @include Text(normal, 0.875rem);

    @media screen and (max-width: 767px) {
      display: none;
    }

    a {
      color: $text_icons-Primary;
      @include Text(normal, 0.875rem);
      text-decoration: none;

      &:hover {
        color: $brand-primary-strong;
      }
    }

    .portfolio {
      display: flex;
      align-items: center;
      gap: 35px;
      padding: 5px 5px 5px 20px;
      border-radius: 30px;
      border: 1px solid #fff;

      .portfolio_quantity {
        color: $text_icons-Secondary;
        background-color: $brand-primary-strong;
        border-radius: 30px;
        padding: 6px 16px;
      }
    }

    .pages {
      display: flex;
      gap: 32px;
      align-items: center;
      padding: 11px 28px;
      border-radius: 30px;
      border: 1px solid #fff;

      a:focus {
        color: $brand-primary-strong;
      }
    }

    .request {
      display: flex;
      align-items: center;
      gap: 19px;
      border-radius: 30px;
      background: #fff;
      color: $text-icons-Secondary;
      padding: 5px 5px 5px 28px;

      button {
        background: none;
        border: none;
        padding: 0;
      }
      :last-of-type {
        border-radius: 30px;
        background: #97d317;
        padding: 6px 12px;
      }
    }
  }

  .burger {
    background-color: transparent;
    border: none;
    padding: 0 0;
    display: flex;
    flex-direction: column;
    height: 14px;
    width: 29px;
    position: relative;

    .line {
      width: 29px;
      height: 4px;
      border-radius: 5px;
      background: $text-icons-Primary;
      position: absolute;
      &:first-of-type {
        top: 0;
      }

      &:last-of-type {
        bottom: 0;
      }

      &:first-of-type.active {
        transform: rotate(45deg);
        top: 5px;
      }

      &:last-of-type.active {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }
  .menu {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999;
    background: $bg-primary;
    width: calc(100% - 30px);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    width: calc(100% - 30px);
    left: 0;
    padding: 0 15px 15px;
    overflow: hidden;
    nav {
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding: 25px;
      border-radius: 30px;
      background: $bg-secondary-subtle;

      a {
        text-decoration: none;
        @include Text(normal, 2rem);
        color: $text-icons-Primary;

        span {
          @include Text(normal, 0.75rem);
          vertical-align: super;
          margin-left: 5px;
        }

        .link_page__orange {
          color: $brand-secondary-strong;
        }
        .link_page__green {
          color: $brand-primary-strong;
        }
        .link_page__pink {
          color: $brand-primary-subtle;
        }
        .link_page__purple {
          color: $brand-primary-badge;
        }
      }
      .dropdown {
        position: relative;
        display: inline-block;

        .dropdown_trigger {
          cursor: pointer;
          @include Text(normal, 2rem);
          color: $text-icons-Primary;
          text-decoration: none;
          display: inline-block;
          width: 100%;
          span {
            @include Text(normal, 0.75rem);
            vertical-align: super;
            margin-left: 5px;
            color: $brand-primary-badge;
          }
          .dropdown_arrow {
            transition: transform 0.3s ease;
            margin-left: auto;
            transform: rotate(0);

            &.open {
              transform: rotate(180deg);
            }
          }
          &:hover {
            color: $brand-primary-strong;
          }
        }

        .dropdown_menu {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          overflow: hidden; // Скрывает содержимое за пределами видимой области
          transition: all 0.3s ease; // Добавляет плавный переход на высоту, видимость и непрозрачность
          padding-left: 10px;
          border-left: 1px solid $text-icons-Primary;
          a {
            @include Text(normal, 1.7rem);
          }
          &.open {
            visibility: visible;
            opacity: 1;
            max-height: 500px; // Задайте максимальную высоту, достаточную для отображения всего контента
            margin-top: 25px;
            transition: all 0.3s ease; // Плавный переход для всех изменений
          }
        }
      }
    }

    .contact {
      text-decoration: none;
      @include Text(normal, 1.25rem);
      color: $text-icons-Primary;
      width: 100%;
      padding: 20px 0;
      border-radius: 255px;
      background: $brand-primary-subtle;
      display: flex;
      justify-content: center;
    }

    .right {
      background: $bg-secondary-subtle;
      border-radius: 30px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        height: 293px;
        justify-content: flex-start;
      }

      h3 {
        @include Text(120%, 2rem);
        @media screen and (max-width: 767px) {
          @include Text(120%, 1.5rem);
          margin-bottom: 20px;
        }
      }

      .social {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
        }

        .links {
          display: flex;
          gap: 10px;

          @media screen and (max-width: 767px) {
            img {
              width: 36px;
              height: 36px;
            }
          }
        }

        p {
          @include Text(24px, 1rem);
        }
      }
    }
  }

  .menu.open {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

.wrapper {
  .nav {
    .designed {
      position: relative;

      button {
        background: none;
        border: none;
        outline: none;
        color: $text_icons-Primary;
        padding: 0;

        &:hover {
          color: $brand-primary-strong;
        }
      }
    }

    .designed__wrapper {
      display: none;
    }

    .designed:hover .designed__wrapper {
      display: flex;
      align-items: flex-end;
      position: absolute;
      top: 15px;
      left: -58.5px;
      z-index: 10;
      width: 177px;
      height: 235px;

      .designed_block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 39px 30px 30px;
        background: url('../../assets/icon/bg.svg') no-repeat center;
        width: 117px;
        height: 146px;
        @include Text(135%, 1rem);
      }
    }
  }
}
