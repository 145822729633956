$text_icons-Primary: #FFFFFF;
$text_icons-Secondary: #111111;

$bg-primary: #030303;
$bg-secondary: #282828;
$bg-secondary-subtle: #121212;

$brand-primary-strong: #92D700;
$brand-primary-subtle: #FF6DB2;
$brand-primary-badge: #703FFF;
$brand-secondary-strong: #FF7A00;
$brand-secondary-subtle: #A586FF;