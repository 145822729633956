.card {
  // display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  transition: 0.5s;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.05);
  // border: 1px #fff solid;
  // border-radius: 50px;
}

.image {
  width: 800px;
  height: 538px;
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 990px) {
  .image {
    width: 600px;
    height: 370px;
  }
}

.details {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: column;
}
.details h3 {
  font-size: 23px;
  color: #fff;
  font-weight: 300;
}

.features {
  font-size: 13px;

  padding: 10px 20px;
  display: flex;
  gap: 30px;
  border-radius: 20px;
}

@media (max-width: 678px) {
  .image {
    max-width: 390px;
    max-height: 260px;
  }
  .details h3 {
    font-size: 16px;
  }
  .features {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .image {
    width: 100%;
    max-width: 100%;
  }
  .details h3 {
    font-size: 14px;
  }
  .features {
    gap: 6px;
  }
}
