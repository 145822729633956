@import 'mixin';
@import 'keyframes';

.adapt__mob {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .adapt__mob {
    display: block !important;
  }
  .adapt__desc {
    display: none !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 39px;
  margin: 53px 0 67px;
  @media screen and (max-width: 767px) {
    margin: 60.84px 0 67px;
    gap: 41px;
  }

  .contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      gap: 0;
      align-items: flex-start;
    }
    .contact {
      text-decoration: none;
      @include Text(normal, 1.25rem);
      color: $text-icons-Primary;
      width: 100%;
      padding: 20px 0;
      border-radius: 255px;
      background: $brand-primary-subtle;
      display: none;
      @media screen and (max-width: 767px) {
        display: flex;
      }
      margin-top: 20px;

      justify-content: center;
    }

    .title {
      @include Title(11.5rem);

      @media screen and (max-width: 767px) {
        @include Title(3.875rem);
      }
    }

    .subtitle {
      @include Text(58px, 3rem);
      max-width: 301px;
      padding-top: 51px;

      @media screen and (max-width: 767px) {
        @include Text(30px, 1.5rem);
        max-width: 100%;
        padding-top: 0;
      }
    }
  }

  .services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
      'dev dev dev design design design'
      'motion motion target target foto pixelArt';

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0 0;
      height: 2138px;
    }

    .services_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 30px;
      height: 244px;
      padding: 25px;
      text-decoration: none;
      white-space: normal;
      color: $text_icons-Primary;

      @media screen and (max-width: 767px) {
        height: 368px;
        padding-bottom: 73px;
      }

      .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }

      .h3 {
        @include Title(1.5rem);
        margin-bottom: 10px;
      }

      .services_item__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        .service_description {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .h3Subtitle {
            @include Text(normal, 1rem);
            max-width: 265px;
            @media screen and (max-width: 767px) {
              @include Text(24px, 1rem);
            }
          }

          .page {
            @include Text(normal, 0.875rem);
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }

    .services_dev {
      grid-area: dev;
      background-color: $brand-primary-strong;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: right;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0px;
      }
      .h3 {
        @include Title(3rem);
        @media screen and (max-width: 767px) {
          @include Title(2rem);
        }
      }

      .dev_bg__wrapper {
        display: flex;
        max-width: 276px;
        flex-wrap: wrap;
        max-height: 138px;
        align-self: flex-end;
        @media screen and (max-width: 767px) {
          align-self: center;
        }

        .dev_bg {
          width: 46px;
          height: 46px;
          border-radius: 50px 0px 0px 0px;
          background: #fff;
          animation-duration: 8s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }

        .dev_bg1 {
          animation-name: dev_bg1;
        }

        .dev_bg2 {
          animation-name: dev_bg2;
        }
        .dev_bg3 {
          animation-name: dev_bg3;
        }
        .dev_bg4 {
          animation-name: dev_bg4;
        }
        .dev_bg5 {
          animation-name: dev_bg5;
        }
        .dev_bg6 {
          animation-name: dev_bg6;
        }
        .dev_bg7 {
          animation-name: dev_bg7;
        }
        .dev_bg8 {
          animation-name: dev_bg8;
        }
        .dev_bg9 {
          animation-name: dev_bg9;
        }
        .dev_bg10 {
          animation-name: dev_bg10;
        }
        .dev_bg11 {
          animation-name: dev_bg11;
        }
        .dev_bg12 {
          animation-name: dev_bg12;
        }
        .dev_bg13 {
          animation-name: dev_bg13;
        }
        .dev_bg14 {
          animation-name: dev_bg14;
        }
        .dev_bg15 {
          animation-name: dev_bg15;
        }
        .dev_bg16 {
          animation-name: dev_bg16;
        }
        .dev_bg17 {
          animation-name: dev_bg17;
        }
        .dev_bg18 {
          animation-name: dev_bg18;
        }
      }
    }

    .services_design {
      grid-area: design;
      background-color: $brand-primary-subtle;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0px;
        margin-top: -48px;
      }

      .h3 {
        @include Title(3rem);
        @media screen and (max-width: 767px) {
          @include Title(2rem);
        }
      }

      .design_bg__wrapper {
        display: flex;
        flex-wrap: wrap;
        max-height: 138px;
        max-width: 276px;
        align-self: flex-end;
        gap: 33px 39px;
        @media screen and (max-width: 767px) {
          align-self: center;
        }

        .design_bg {
          width: 24px;
          height: 24px;
          border-radius: 100px;
          background: #fff;
          z-index: 2;
        }

        .design_bg1__wrapper {
          position: relative;
          width: 87px;
          height: 24px;

          .design_bg1 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg1;
          }
        }

        .design_bg2__wrapper {
          position: relative;
          width: 24px;
          height: 81px;
          .design_bg2 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-name: design_bg2;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }
        }

        .design_bg3__wrapper {
          position: relative;
          width: 24px;
          height: 81px;
          .design_bg3 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-name: design_bg3;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }
        }
        .design_bg4__wrapper {
          position: relative;
          width: 87px;
          height: 24px;

          .design_bg4 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg4;
          }
        }
        .design_bg5__wrapper {
          position: relative;
          width: 24px;
          height: 81px;
          .design_bg5 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-name: design_bg5;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }
        }
        .design_bg6__wrapper {
          position: relative;
          width: 24px;
          height: 81px;
          .design_bg6 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-name: design_bg6;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }
        }
        .design_bg8__wrapper {
          position: relative;
          width: 87px;
          height: 24px;

          .design_bg8 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg8;
          }
        }
        .design_bg9__wrapper {
          position: relative;
          width: 24px;
          height: 81px;
          .design_bg9 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-name: design_bg9;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }
        }
        .design_bg10 {
          animation-name: design_bg10;
        }
        .design_bg11__wrapper {
          position: relative;
          width: 87px;
          height: 24px;

          .design_bg11 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg11;
          }
        }
        .design_bg12__wrapper {
          position: relative;
          width: 87px;
          height: 24px;

          .design_bg12 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg4;
          }
        }
        .design_bg13 {
          animation-name: design_bg13;
        }
        .design_bg14__wrapper {
          .design_bg14 {
          }
        }
        .design_bg15__wrapper {
          position: relative;
          width: 87px;
          height: 24px;
          rotate: 180deg;
          left: -63px;

          .design_bg15 {
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;
            background: #fff;
            animation-duration: 8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: design_bg11;
          }

          .design_bg15__inner {
            position: absolute;
            right: 0;
            width: 24px;
            height: 24px;
            border-radius: 100px;
            background: #fff;
          }
        }
      }
    }

    .services_motion {
      grid-area: motion;
      background-color: $brand-primary-badge;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: right;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0px;
        margin-top: -48px;
      }

      .h3 {
        @include Title(2rem);
      }

      .motion_bg__wrapper {
        align-self: flex-end;
        @media screen and (max-width: 767px) {
          align-self: center;
        }

        .motion_bg1 {
          width: 138px;
          height: 138px;
          background: #ffffff;
          border-radius: 100px;
          display: flex;
          align-items: center;
          position: relative;
        }

        .motion_bg2 {
          background: $bg_primary;
          width: 36px;
          height: 54px;
          border-radius: 36px / 54px;
          position: absolute;
          left: 5px;
          animation-name: motion;
          animation-duration: 8s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      }
    }

    .services_target {
      grid-area: target;
      background-color: $brand-secondary-strong;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: right;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0px;
        margin-top: -48px;
      }

      .h3 {
        @include Title(2rem);
      }

      .target_bg__wrapper {
        display: flex;
        align-self: flex-end;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 138px;
        height: 138px;
        @media screen and (max-width: 767px) {
          align-self: center;
        }

        .target_bg {
          position: absolute;
          width: 17px;
          height: 138px;
          background: $text_icons-Primary;
          animation: target 8s linear infinite;
        }

        .target_bg1 {
          position: absolute;
          rotate: 90deg;
        }

        .target_bg2 {
          animation: target 8s linear infinite;
          position: absolute;

          .target_bg2_inner {
            height: 0;
            position: absolute;
            bottom: 0;
            background: $brand-primary-strong;
            animation: target_bg2 8s linear infinite;
            width: 100%;
          }
        }

        .target_bg3 {
          rotate: -45deg;
          animation: target 8s linear infinite;
          position: absolute;

          .target_bg3_inner {
            height: 0;
            position: absolute;
            top: 0;
            background: $brand-primary-subtle;
            animation: target_bg3 8s linear infinite;
            width: 100%;
          }
        }

        .target_bg4 {
          rotate: 45deg;
          animation: target 8s linear infinite;
          position: absolute;

          .target_bg4_inner {
            height: 0;
            position: absolute;
            top: 0;
            background: $brand-primary-badge;
            animation: target_bg4 8s linear infinite;
            width: 100%;
          }
        }
      }
    }

    .fotoArt {
      grid-area: fotoArt;
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: 466px;
      background: $bg_primary;
      border-radius: 30px;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0;
        margin-top: -48px;
      }
    }

    .page {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .services_itemMini__wrapper {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        justify-content: space-between;
      }
    }

    .services_itemMini__bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }

    .services_foto {
      grid-area: foto;
      background-color: #fff;
      color: $bg_primary;
      @media screen and (max-width: 767px) {
        height: 226px;
        padding-bottom: 25px;
      }

      .h3 {
        color: $bg_primary !important;
        @include Title(1.5rem);
      }

      .foto_bg__wrapper {
        position: relative;
        width: 89px;
        height: 83px;
        @media screen and (max-width: 767px) {
          margin: 0 auto;
        }

        .foto_bg1 {
          width: 38px;
          height: 38px;
          position: absolute;
          top: 0;
          left: 0;
          background: #000000;
          border-radius: 255px;
          animation: foto_bg1 8s linear infinite;
        }
        .foto_bg2 {
          width: 38px;
          height: 38px;
          position: absolute;
          top: 0;
          left: 38px;
          background: #000000;
          border-radius: 255px;
          animation: foto_bg2 8s linear infinite;
        }
        .foto_bg3 {
          width: 38px;
          height: 38px;
          position: absolute;
          top: 38px;
          left: 0;
          background: #000000;
          animation: foto_bg3 8s linear infinite;
        }
        .foto_bg4 {
          position: absolute;
          width: 38px;
          height: 38px;
          top: 38px;
          left: 38px;
          animation: foto_bg4 8s linear infinite;

          path {
            animation: foto_bg4_inner 8s linear infinite;
          }
        }
      }
    }

    .services_pixelArt {
      grid-area: pixelArt;
      border: 1px solid #fff;
      height: 242px;
      background: #030303;

      @media screen and (max-width: 767px) {
        height: 220px;
        padding-bottom: 25px;
      }

      .h3 {
        @include Title(1.5rem);
      }

      .pixel_bg__wrapper {
        height: 64px;
        width: 104px;
        position: relative;
        @media screen and (max-width: 767px) {
          margin: 0 auto;
        }
        .pixel_bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 104px;
          animation: pixel_bg 8s linear infinite;

          rect {
            animation: rect_color 8s linear infinite;

            &:nth-of-type(1) {
              animation: pixel_bg1 8s linear infinite;
            }
            &:nth-of-type(48) {
              animation: pixel_bg48 8s linear infinite;
            }
            &:nth-of-type(37) {
              animation: pixel_bg37 8s linear infinite;
            }
            &:nth-of-type(27) {
              animation: pixel_bg27 8s linear infinite;
            }
            &:nth-of-type(8) {
              animation: pixel_bg8 8s linear infinite;
            }
            &:nth-of-type(46) {
              animation: pixel_bg46 8s linear infinite;
            }
          }
        }
      }
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  background: $bg-secondary-subtle;
  border-radius: 50px;
  padding: 70px 0 0;
  gap: 207px;
  margin-bottom: 111px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding: 42px 0 0;
    margin-top: 150px;
  }

  .about_title {
    text-align: center;
    @include Text(96px, 6rem);

    @media screen and (max-width: 767px) {
      @include Text(36px, 2rem);
    }

    span {
      white-space: pre-wrap;
    }

    img {
      vertical-align: middle;
      margin: 0 15px;

      @media screen and (max-width: 767px) {
        width: 36px;
        height: 36px;
      }
    }

    .about_description__green {
      color: $brand-primary-strong;
    }

    .about_description__orange {
      color: $brand-secondary-strong;
    }

    .about_description__pink {
      color: $brand-primary-subtle;
    }
  }

  .about_animation {
    position: relative;
    height: 347px;
    @media screen and (max-width: 767px) {
      width: 345px;
      margin: 0 auto;

      img {
        width: 36px;
        height: 36px;
      }
    }

    .text_item {
      @include Text(normal, 2rem);
      border-radius: 255px;
      padding: 20px 30px;
      display: inline-block;

      @media screen and (max-width: 767px) {
        @include Text(normal, 1.25rem);
        padding: 10px 20px;
        white-space: nowrap;
      }
    }

    .sort {
      position: absolute;
      bottom: 287px;
      left: 570px;
      animation: sort 8s linear;
      @media screen and (max-width: 767px) {
        bottom: 173px;
        left: 265px;
        animation: none;
      }
    }

    .folder {
      position: absolute;
      left: 857px;
      bottom: 246px;
      animation: folder 8s linear;
      @media screen and (max-width: 767px) {
        bottom: 240px;
        left: 6px;
        animation: none;
      }
    }

    .versatility {
      color: $brand-primary-strong;
      border: 3px solid $brand-primary-strong;
      transform: rotate(20.58deg);
      position: absolute;
      left: 646.58px;
      bottom: 242px;
      animation: versatility 8s linear;
      @media screen and (max-width: 767px) {
        bottom: 86.71px;
        left: -3.93px;
        animation: none;
        transform: rotate(11.754deg);
      }
    }

    .partner {
      position: absolute;
      color: $brand-secondary-strong;
      border: 3px solid $brand-secondary-strong;
      transform: rotate(15.535deg);
      left: 354.08px;
      bottom: 217.88px;
      animation: partner 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 125.45px;
        left: 104.01px;
        animation: none;
        transform: rotate(12.153deg);
      }
    }

    .share {
      position: absolute;
      left: 497px;
      bottom: 131px;
      animation: share 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 162px;
        left: 304px;
        animation: none;
      }
    }

    .quality {
      position: absolute;
      color: $brand-primary-badge;
      border: 3px solid $brand-primary-badge;
      transform: rotate(-17.94deg);
      left: 571.98px;
      bottom: 115.85px;
      animation: quality 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 33.15px;
        left: 35.63px;
        animation: none;
        transform: rotate(-15.649deg);
      }
    }

    .creative {
      position: absolute;
      color: $brand-primary-subtle;
      border: 3px solid $brand-primary-subtle;
      transform: rotate(21.63deg);
      left: 250.04px;
      bottom: 85.67px;
      animation: creative 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 197.36px;
        left: 1.98px;
        animation: none;
        transform: rotate(-9.559deg);
      }
    }

    .here {
      position: absolute;
      color: $text_icons-Primary;
      border: 3px solid $text_icons-Primary;
      transform: rotate(9.979deg);
      right: 165.02px;
      bottom: 57.27px;
      animation: here 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 233.53px;
        right: -5.01px;
        animation: none;
        transform: rotate(32.757deg);
      }
    }

    .at {
      position: absolute;
      bottom: 41px;
      left: 264px;
      animation: at 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 94px;
        left: 136px;
        animation: none;
      }
    }

    .hashtag {
      position: absolute;
      bottom: 41px;
      left: 798px;
      animation: hashtag 8s linear;

      @media screen and (max-width: 767px) {
        bottom: 7px;
        left: 194px;
        animation: none;
      }
    }
  }
}

.choice {
  display: flex;
  flex-direction: column;
  gap: 84px;
  margin-bottom: 152px;
  @media screen and (max-width: 767px) {
    gap: 57px;
    max-height: 2969px;
    margin-bottom: 130px;
  }

  .title__wrapper {
    display: flex;
    gap: 103px;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: center;
    }

    .title {
      @include Text(96px, 6rem);
      @media screen and (max-width: 767px) {
        @include Text(36px, 2rem);
        text-align: center;
      }
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 160px;
      @include Text(32px, 1.5rem);
      @media screen and (max-width: 767px) {
        @include Text(20px, 1rem);
        text-align: center;
        height: max-content;

        :first-child {
          color: $brand-primary-strong;
        }

        :last-child {
          color: $brand-primary-subtle;
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 452px 336px;
    gap: 10px 10px;
    grid-template-areas:
      'card1 card1 card2 card2 card3 card3'
      'card4 card4 card4 card5 card5 card5';
    justify-items: end;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0 0;
    }
  }
  .card {
    border-radius: 35px;
    border: 3px solid $text_icons-Primary;
    @media screen and (max-width: 767px) {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 490px;
      background: #000;
    }

    .switch {
      border-radius: 255px;
      border: 3px solid $text_icons-Primary;
      width: 90px;
      height: 44px;
      padding: 5px;
      background: transparent;
      margin-bottom: 171px;
      position: relative;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }

      .switch_circle {
        width: 30px;
        height: 30px;
        background: $text_icons-Primary;
        border-radius: 255px;
        position: absolute;
        left: 5px;
        top: calc(50% - 15px);
        animation: switchBack 0.2s linear;
      }
    }

    .card_title {
      @include Title(2rem);
      margin-bottom: 23px;
      @media screen and (max-width: 767px) {
        @include Title(1.5rem);
        margin-bottom: 19px;
      }
    }

    .card_description {
      @include Text(normal, 1.25rem);
      @media screen and (max-width: 767px) {
        @include Text(normal, 1rem);
        max-width: 268px;
      }
    }
  }

  //.card.active {
  //  .switch {
  //    .switch_circle {
  //      left: 50px;
  //      animation: switch 0.2s linear;
  //    }
  //  }
  //}

  .card1 {
    grid-area: card1;
    padding: 25px 66px 0 25px;
    background-image: none;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
    }
  }

  .card.card1.card1__bg {
    background-image: url('../assets/image/cardBg_pink.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card2 {
    grid-area: card2;
    padding: 25px 66px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }
  }

  .card.card2.card2__bg {
    background-image: url('../assets/image/cardBg_green.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card3 {
    grid-area: card3;
    padding: 25px 35px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }
  }

  .card.card3.card3__bg {
    background-image: url('../assets/image/cardBg_orange.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card4 {
    grid-area: card4;
    padding: 25px 257px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }

    .switch {
      margin-bottom: 82px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  .card.card4.card4__bg {
    background-image: url('../assets/image/cardBg_purple.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card5 {
    grid-area: card5;
    padding: 25px 256px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }

    .switch {
      margin-bottom: 82px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  .card.card5.card5__bg {
    background-image: url('../assets/image/cardBg_white.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch {
      border: 3px solid $text_icons-Secondary;

      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
        background: $text_icons-Secondary;
      }
    }

    .card__wrapper {
      color: $text-icons-Secondary;

      .card_title {
        color: $text-icons-Secondary;
      }
    }
  }
}

.teams {
  position: relative;
  text-align: center;
  height: 300vh;

  .title__wrapper {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    @media screen and (max-width: 767px) {
      height: 452px;
    }

    .badge {
      padding-top: 120px;
      @include Text(32px, 1.5rem);
      color: $brand-primary-strong;
      margin-bottom: 21px;
      @media screen and (max-width: 767px) {
        @include Text(20px, 1rem);
        margin-bottom: 20px;
      }
      &::after {
        width: 100px;
        height: 100px;
        background: red;
      }
    }

    .title {
      @include Text(96px, 6rem);
      max-width: 1102px;
      @media screen and (max-width: 767px) {
        @include Text(36px, 2rem);
        max-width: max-content;
      }
    }

    .buttonContainer {
      position: relative;
      width: 280px;
      height: 82px;
      margin-top: 66px;

      .teams_button {
        padding: 20px 30px;
        @include Text(normal, 2rem);
        background: $brand-primary-badge;
        border: none;
        animation: colorButton 4s linear infinite;
        border-radius: 255px;
        color: $text-icons-Primary;
        width: 280px;
      }

      .showButton {
        position: absolute;
        width: 0;
        height: 82px;
        top: 0;
        background: $bg-primary;
        animation: showButton 0.5s linear;
      }
    }
  }

  .teams_cards {
    top: calc(50% - 50vh);
    height: 100vh;
    z-index: 2;
    width: 100%;
    position: absolute;
    ::-webkit-scrollbar {
      width: 0;
      //-webkit-appearance: none;
      //height: 0;
      //display: none;
      -webkit-overflow-scrolling: touch;
    }

    .teams_card {
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      border-radius: 30px;
      background: #000000;
      text-align: left;

      img {
        max-width: 152px;
        width: 100%;
        border-radius: 30px;
      }
      @media screen and (max-width: 767px) {
        img {
          width: 100px;
        }
      }

      .teams_card__wrapper {
        max-width: 152px;
        @media screen and (max-width: 767px) {
          max-width: 100px;
        }

        .teams_card__name {
          @include Text(120%, 1.5rem);
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            @include Text(120%, 1.25rem);
          }
        }

        .teams_card__description {
          @include Text(125%, 1rem);

          @media screen and (max-width: 767px) {
            @include Text(125%, 0.875rem);
          }
        }
      }
    }

    .teams_card1 {
      position: absolute;
      left: 840px;
      border: 3px solid $text_icons-Primary;
      @media screen and (max-width: 767px) {
        right: 2px;
        left: auto;
      }
    }

    .teams_card2 {
      position: absolute;
      left: 0;
      top: 136px;
      border: 3px solid $brand-primary-strong;
      @media screen and (max-width: 767px) {
        left: 0;
      }
    }

    .teams_card3 {
      position: absolute;
      left: 560px;
      top: 281px;
      border: 3px solid $brand-primary-badge;
      @media screen and (max-width: 767px) {
        right: 2px;
        left: auto;
      }
    }

    .teams_card4 {
      position: absolute;
      right: 0;
      top: 539px;
      border: 3px solid $brand-primary-subtle;
      @media screen and (max-width: 767px) {
        left: 2px;
        right: auto;
      }
    }

    .teams_card5 {
      position: absolute;
      left: 280px;
      top: 616px;
      border: 3px solid $brand-secondary-strong;
      @media screen and (max-width: 767px) {
        right: 2px;
        left: auto;
      }
    }
  }
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 96px;
  @media screen and (max-width: 767px) {
    margin-top: 81.67px;
    margin-bottom: 50px;
  }
  .title__wrapper {
    text-align: center;
    margin-bottom: 79px;
    @media screen and (max-width: 767px) {
      margin-bottom: 57px;
    }

    .badge {
      @include Text(32px, 1.5rem);
      margin-bottom: 21px;
      color: $brand-primary-subtle;
      @media screen and (max-width: 767px) {
        @include Text(20px, 1rem);
      }
    }

    .title {
      @include Text(96px, 6rem);
      @media screen and (max-width: 767px) {
        @include Text(36px, 2rem);
      }
    }
  }

  .projects_cards {
    display: flex;
    gap: 10px;

    a {
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0;
    }

    .projects_cards__wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .projects_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px;
      height: 489px;
      border-radius: 30px;
      background: #000;
      color: $text_icons-Primary;
      text-decoration: none;
      white-space: normal;
      @media screen and (max-width: 767px) {
        height: 530px;
        padding: 25px 25px 75px;
      }

      .projects_card__preview {
        width: 266px;
        height: 202px;
        border-radius: 20px;
        object-fit: cover;
        @media screen and (max-width: 767px) {
          align-self: center;
        }
      }

      .projects_card__title {
        @include Text(120%, 1.5rem);
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          @include Text(normal, 1.5rem);
          margin-bottom: 19px;
        }
      }

      .projects_card__description {
        @include Text(125%, 1rem);
        @media screen and (max-width: 767px) {
          @include Text(normal, 1rem);
        }
      }
    }

    .projects_card1 {
      margin-top: 152px;
      @media screen and (max-width: 767px) {
        margin-top: 0;
        position: sticky;
        top: 0;
      }
    }

    .projects_card5 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
      }
    }

    .projects_card2 {
      margin-top: 68px;
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
      }
    }

    .projects_card3 {
      margin-top: 254px;

      .projects_card__preview {
        background: white;
        width: 266px;
        height: 202px;
        border-radius: 20px;
        object-fit: cover;
        @media screen and (max-width: 767px) {
          align-self: center;
        }
      }
    }

    .projects_card4 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
      }
    }

    .projects_card6 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
      }
    }
    .projects_card8 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }

    .projects_card7 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }

    .projects_card5 {
      @media screen and (max-width: 767px) {
        margin-top: -50px;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }

    .projects_card1,
    .projects_card3,
    .projects_card7 {
      border: 3px solid $brand-primary-strong;
    }

    .projects_card2,
    .projects_card8 {
      border: 3px solid $brand-primary-badge;
    }

    .projects_card4,
    .projects_card6 {
      border: 3px solid $brand-primary-subtle;
      z-index: 10;
    }

    .projects_card5 {
      border: 3px solid $brand-secondary-strong;
    }
  }
}
.banner {
  height: 100vh;
}
