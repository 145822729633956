.contents {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  margin-top: 48px;
  margin-bottom: 32px;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-start;
  }

  .title {
    font-size: 128px;
    @media screen and (max-width: 767px) {
      font-size: 48px;
    }
}
}