@import "mixin";
@import "keyframes";

.adapt__mob {
  display: none!important;
}

@media screen and (max-width: 767px) {

.adapt__mob {
 display: block!important;
}
.adapt__desc {
  display: none!important;
}
}

.title__container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 100px 0;
}

.title__header h1{
  font-size: 96px;
  font-weight: 400;
}

.title__suptitle {
  display: flex;
  gap: 50px;
  justify-content: flex-end;
  align-items: flex-end;
}

.title__btn {
  border-radius: 255px;
  background-color: #703FFF;
  color: #fff;
  border: none;
  padding: 20px;
  text-decoration: none;
}

.title__sup {
  max-width: 450px;
  font-size: 20px;
  line-height: 24px;
}

.span__green {
  color: #92D700;  
}
.span__pink {
  color: #FF6DB2;
}
.span__orange {
  color: #FF7A00;
}

@media screen and (max-width: 767px) {
  .title__container {
    gap: 40px;
  }
  .title__header h1{
    font-size: 32px;
  }
  .title__suptitle {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}


.about {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  padding: 70px 0 0;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding: 42px 0 0;
    // margin-top: 150px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 109px;
    @media screen and (max-width: 767px) {
      margin-bottom: 41px;
    }
  }
  &_badge {
    padding: 15px 20px;
    border-radius: 255px;
    border: 2px solid $text_icons-Primary;
    @media screen and (max-width: 767px) {
      padding: 10px;
      @include Text(normal, 0.75rem);
    }
  }

  .about_title {
    text-align: center;
    @include Text(96px, 6rem);

    @media screen and (max-width: 767px) {
      @include Text(36px, 2rem);
    }

    span {
      white-space: pre-wrap;
    }

    img {
      vertical-align: middle;
      margin: 0 15px;

      @media screen and (max-width: 767px) {
        width: 36px;
        height: 36px;
      }
    }
  }
// @media screen and (max-width: 767px) {
//   .about {
//     margin-top: 0px;
//   }
// }

}



.our__process {
  padding: 100px 0;
}
.our__process h1{
  font-size: 96px;
}
.process__container {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
}

.process__card {
  display: flex;
  justify-content: space-between;
  border: 3px #fff solid;
  border-radius: 30px;
  padding: 25px;
  overflow: hidden;
}
.process__card h4 {
  font-size: 20px;
  padding-top: 5px;
}
.process__card img {
  width: 150px;
  height: 120px;
  margin-top: -10px;
}
.process__card h2 {
  max-width: 340px;
  font-size: 32px;
  font-weight: 400;
  margin-top: 20px;
}
.process__card p {
  line-height: 20px;
  max-width: 400px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .our__process{
    padding-top: 0px;
    padding-bottom: 100px;
  }
  .process__container {
    gap: 10px;
  }
.our__process h1{
  font-size: 48px;
}

.process__card {
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.process__card > div:nth-child(-n+2) {
  flex: 1 0 50%;
 }
 
 .process__card > div:nth-child(n+3) {
  flex: 1 0 100%;
 }
 .process__card img {
  margin-top: -20px;
  margin-left: -20px;
}
.process__card h2 {
  margin-top: 80px;
}


}




.join {
  text-align: center;
  padding: 80px;
  border-radius: 50px;
  background-color: #703FFF;
  background-repeat: no-repeat;
  background-image: url(../assets/image/Flame.png);
  background-position: center;
}
.join h1 {
font-size: 96px;
font-weight: 400;
}
.join p {
  line-height: 24px;
  padding: 44px 0 68px 0;
}
.join a {
  padding: 30px;
  font-size: 32px;
  border-radius: 255px;
  background-color: #FF7A00;
  color: #fff;
  border: none;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .join h1 {
    font-size: 48px;
    }
  .join {
    padding: 160px 20px;
  }
.join a {
  font-size: 20px;
  padding: 20px;
}

}






.choice {
    display: flex;
    flex-direction: column;
    gap: 84px;
    padding: 140px 0 20px 0;
    @media screen and (max-width: 767px) {
      gap: 57px;
      max-height: 2969px;
      margin-bottom: 130px;
    }
    
  
    .title__wrapper {
      display: flex;
      gap: 103px;
      align-items: flex-end;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        align-items: center;
      }
  
      .title {
        @include Text(96px, 6rem);
        @media screen and (max-width: 767px) {
          @include Text(36px, 2rem);
          text-align: center;
        }
      }
  
      .subtitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
        @include Text(32px, 1.5rem);
        @media screen and (max-width: 767px) {
          @include Text(20px, 1rem);
          text-align: center;
          height: max-content;
  
          :first-child {
            color: $brand-primary-strong;
          }
  
          :last-child {
            color: $brand-primary-subtle;
          }
        }
      }
    }
  
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 452px 140px;
      gap: 10px 10px;
      grid-template-areas:
      "card1 card1 card2 card2 card3 card3"
      "card4 card4 card4 card5 card5 card5";
      justify-items: end;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 0 0;
      }
    }
    .choise__one {
      grid-template-rows: 0 336px;
      // padding: 130px 0;
    }
    .card {
      border-radius: 35px;
      border: 3px solid $text_icons-Primary;
      @media screen and (max-width: 767px) {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 490px;
        background: #000;
      }
  
      .switch {
        border-radius: 255px;
        border: 3px solid $text_icons-Primary;
        width: 90px;
        height: 44px;
        padding: 5px;
        background: transparent;
        margin-bottom: 171px;
        position: relative;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
  
        .switch_circle {
          width: 30px;
          height: 30px;
          background: $text_icons-Primary;
          border-radius: 255px;
          position: absolute;
          left: 5px;
          top: calc(50% - 15px);
          animation: switchBack 0.2s linear;
        }
      }
  
      .card_title {
        @include Title(2rem);
        margin-bottom: 23px;
        @media screen and (max-width: 767px) {
          @include Title(1.5rem);
          margin-bottom: 19px;
        }
      }
  
      .card_description {
        @include Text(normal, 1.25rem);
        @media screen and (max-width: 767px) {
          @include Text(normal, 1rem);
          max-width: 268px;
        }
      }
    }
    .card__icons {
      display: flex;
      gap: 10px;
      align-items: flex-start;
    }
  
    .card1 {
      grid-area: card1;
      padding: 25px 66px 0 25px;
      background-image: none;
      @media screen and (max-width: 767px) {
        padding: 25px 25px 75px 25px;
      }
    }
  
  
    .card.card1.card1__bg {
      background-image: url("../assets/image/cardBg_pink.png");
      background-size: cover;
      background-position: 50%;
      background-position-y: 0;
      animation: card1__bg 1s linear;
      background-repeat: no-repeat;
  
  
      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
      }
    }
  
  
    .card2 {
      grid-area: card2;
      padding: 25px 66px 0 25px;
      @media screen and (max-width: 767px) {
        padding: 25px 25px 75px 25px;
        margin-top: -50px;
      }
    }
  
    .card.card2.card2__bg {
      background-image: url("../assets/image/cardBg_green.png");
      background-size: cover;
      background-position: 50%;
      background-position-y: 0;
      animation: card1__bg 1s linear;
      background-repeat: no-repeat;
  
  
      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
      }
    }
  
    .card3 {
      grid-area: card3;
      padding: 25px 35px 0 25px;
      @media screen and (max-width: 767px) {
        padding: 25px 25px 75px 25px;
        margin-top: -50px;
      }
    }
  
    .card.card3.card3__bg {
      background-image: url("../assets/image/cardBg_orange.png");
      background-size: cover;
      background-position: 50%;
      background-position-y: 0;
      animation: card1__bg 1s linear;
      background-repeat: no-repeat;
  
  
      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
      }
    }
  
    .card__flex {
      display: flex;
      justify-content: space-between;
    }
    .card4 {
      grid-area: card4;
      padding: 25px 30px 0 25px;
      @media screen and (max-width: 767px) {
        padding: 25px 25px 25px 25px;
        margin-top: -50px;
      }
  
      .switch {
        margin-bottom: 82px;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }
  
    .card.card4.card4__bg {
      background-image: url("../assets/image/cardBg_pin.png");
      background-size: cover;
      background-position: 50%;
      background-position-y: 0;
      animation: card1__bg 1s linear;
      background-repeat: no-repeat;
    
      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
      }
    }
  
    .card5 {
      grid-area: card5;
      padding: 25px 30px 0 25px;
      @media screen and (max-width: 767px) {
        padding: 25px 25px 75px 25px;
        margin-top: -50px;
      }
  
      .switch {
        margin-bottom: 82px;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }
  
    .card.card5.card5__bg {
      background-image: url("../assets/image/cardBg_orang.png");
      background-size: cover;
      background-position: 50%;
      background-position-y: 0;
      animation: card1__bg 1s linear;
      background-repeat: no-repeat;
  
  
      .switch {
        border: 3px solid #fff;
  
        .switch_circle {
          animation: switch 1s linear;
          left: 50px;
          background: #fff;
        }
      }
  
      .card__wrapper {
        color: #fff;
  
        .card_title {
          color: #fff;
        }
      }
      
    }
  }

@media screen and (max-width: 767px) {
  .card.card5.card5__bg {
    background-image: none!important;
  }
  .card.card5 button {
    display: none;
  }
  .card.card5 {
    height: 320px;
    margin-top: 10px;
  }
  .card.card4.card4__bg {
    background-image: none!important;
  }
  .card.card4 {
    height: 320px;
    border-radius: 30px;
  }
  .card.card4 button {
    display: none;
  }

}



  .start__title {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .start__title h1 {
    font-size: 96px;
  }
  .start__title p {
    font-size: 24px;
    color: #703FFF;
  }

  .start__container {
    padding-top: 40px;
    padding-bottom: 150px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }
  .start__item {
    padding: 25px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 202px;
  }
  .start__item h2 {
    font-size: 20px;
  }
  .start__item h1 {
    font-size: 32px;
    padding-bottom: 20px;
  }
  .start__item p {
    max-width: 207px;
  }
  .start__card1 {
    height: 250px;
    border: 3px solid #92D700;
  }
  .start__card2 {
    height: 300px;
    border: 3px solid #FF6DB2
  }
  .start__card3 {
    height: 350px;
    border: 3px solid #FFFFFF
  }
  .start__card4 {
    height: 400px;
    border: 3px solid #FF7A00;
  }
  .start__card5 {
    height: 450px;
    border: 3px solid #703FFF
  }

  @media screen and (max-width: 767px) {
    .start__container {
      flex-direction: column;
      align-items: normal;
    }
    .start__item {
      height: 240px;
      max-width: 343px;
    }
    .start__title h1 {
      font-size: 48px;
    }
  }

  .accelerate {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 70px;
  }

  .accelerate h1 {
    font-size: 84px;
    font-weight: 400;
    line-height: 100px;
    max-width: 738px;
    text-align: center;
  }

  .accelerate p {
    font-size: 32px;
    font-weight: 400;
    max-width: 950px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
  .accelerate h1 {
    font-size: 48px;
    line-height: 50px;
  }
  .accelerate p {
    font-size: 24px;
  }

  }


