// .container {
//   max-width: 1320px;
//   margin: 0 auto;
//   padding: 20px;
// }
@import 'mixin';
@import 'keyframes';
.smm__mobile {
  display: none !important;
}

.arrow__smm {
  width: 150px;
  margin: 0 auto;
  display: flex;
  gap: 14px;
  align-items: center;
}
.arrow__smm div {
  width: 10px;
  height: 10px;
  border-radius: 500px;
  background-color: #fff;
}

.tabs {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 20px;
  border: 1px solid #fff;
  border-radius: 60px;
  max-width: 888px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 1;
}

.tab {
  padding: 20px;
  font-size: 27px;
  transition: background-color 0.3s ease;
}

.tab.active {
  color: rgba(146, 215, 2, 1);
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  padding: 60px 0;
  justify-content: space-between;
}

.content {
  img {
    width: 100% !important;
  }
}
.webContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 146px;
}

.cardWrapper {
  margin-bottom: 50px;
  width: fit-content;
}

.scaleWrapper {
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;
  will-change: transform, opacity;
}
.cardWrapper {
  margin-bottom: 50px;
}

.yearSpacer {
  position: relative;
  width: 100%;
  text-align: center;
}

.yearAbsolute {
  position: sticky;
  top: 10%;
  left: 0;
  text-align: center;
  opacity: 0.2;

  font-weight: 300;
  background: linear-gradient(to bottom, #fff, transparent);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  z-index: -1;
  pointer-events: none;
}

.content.active {
  display: block;
}

@media (max-width: 678px) {
  .container {
    padding: 0;
  }
  .tabs {
    flex-direction: column;
    align-items: center;
  }
  .content {
    justify-content: center;
    gap: 30px;
  }
}

.smm__title {
  font-size: 64px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 150px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.smm__slider {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  row-gap: 20px;
}
.smm__slider img {
  max-width: 280px;
  max-height: 580px;
}

.smm__info {
  display: flex;
  flex-direction: column;
  margin-top: 110px;
}
.smm__info__item {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  border-bottom: 1px solid #ffffff66;
}
.smm__border {
  border-top: 1px solid #ffffff66;
}
.smm_info__left {
  display: flex;
  gap: 150px;
  justify-content: space-between;
  align-items: center;
}
.smm_info__left h1 {
  font-size: 32px;
  max-width: 340px;
}
.smm_info__left h2 {
  font-size: 16px;
  max-width: 33px;
}
.smm_info__right p {
  font-size: 16px;
  max-width: 480px;
}

.smm__after {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.smm__after h1 {
  font-size: 64px;
  text-transform: uppercase;
  font-weight: 400;
}

@media (max-width: 768px) {
  .smm__after {
    display: none;
  }
  .smm__title {
    font-size: 39px;
  }
  .smm__mobile {
    display: block !important;
  }
  .smm__slider {
    gap: 20px;
  }
  .smm__desct {
    display: none;
  }

  .smm__info__item {
    flex-direction: column;
  }
  .smm_info__left {
    gap: 0px;
    padding-bottom: 40px;
  }
  .smm_info__left h2 {
    width: 33px;
  }
  .smm_info__left h1 {
    width: 100%;
    text-align: center;
  }
}

.choice {
  display: flex;
  flex-direction: column;
  gap: 84px;
  padding: 0px 0 20px 0;
  @media screen and (max-width: 767px) {
    gap: 57px;
    max-height: 2969px;
    margin-bottom: 130px;
  }

  .title__wrapper {
    display: flex;
    gap: 103px;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: center;
    }

    .title {
      @include Text(96px, 6rem);
      @media screen and (max-width: 767px) {
        @include Text(36px, 2rem);
        text-align: center;
      }
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 160px;
      @include Text(32px, 1.5rem);
      @media screen and (max-width: 767px) {
        @include Text(20px, 1rem);
        text-align: center;
        height: max-content;

        :first-child {
          color: $brand-primary-strong;
        }

        :last-child {
          color: $brand-primary-subtle;
        }
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 452px 0px;
    gap: 10px 10px;
    grid-template-areas:
      'card1 card1 card2 card2 card3 card3'
      'card4 card4 card4 card5 card5 card5';
    justify-items: end;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0 0;
    }
  }
  .choise__one {
    grid-template-rows: 0 336px;
    // padding: 130px 0;
  }
  .card {
    border-radius: 35px;
    border: 3px solid $text_icons-Primary;
    @media screen and (max-width: 767px) {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 490px;
      background: #000;
    }

    .switch {
      border-radius: 255px;
      border: 3px solid $text_icons-Primary;
      width: 90px;
      height: 44px;
      padding: 5px;
      background: transparent;
      margin-bottom: 171px;
      position: relative;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }

      .switch_circle {
        width: 30px;
        height: 30px;
        background: $text_icons-Primary;
        border-radius: 255px;
        position: absolute;
        left: 5px;
        top: calc(50% - 15px);
        animation: switchBack 0.2s linear;
      }
    }

    .card_title {
      @include Title(2rem);
      margin-bottom: 23px;
      @media screen and (max-width: 767px) {
        @include Title(1.5rem);
        margin-bottom: 19px;
      }
    }

    .card_description {
      @include Text(normal, 1.25rem);
      @media screen and (max-width: 767px) {
        @include Text(normal, 1rem);
        max-width: 268px;
      }
    }
  }
  .card__icons {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  .card1 {
    grid-area: card1;
    padding: 25px 66px 0 25px;
    background-image: none;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
    }
  }

  .card.card1.card1__bg {
    background-image: url('../assets/image/cardBg_pink.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card2 {
    grid-area: card2;
    padding: 25px 66px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }
  }

  .card.card2.card2__bg {
    background-image: url('../assets/image/cardBg_green.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card3 {
    grid-area: card3;
    padding: 25px 35px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }
  }

  .card.card3.card3__bg {
    background-image: url('../assets/image/cardBg_orange.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card__flex {
    display: flex;
    justify-content: space-between;
  }
  .card4 {
    grid-area: card4;
    padding: 25px 30px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 25px 25px;
      margin-top: -50px;
    }

    .switch {
      margin-bottom: 82px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  .card.card4.card4__bg {
    background-image: url('../assets/image/cardBg_pin.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch_circle {
      animation: switch 1s linear;
      left: 50px;
    }
  }

  .card5 {
    grid-area: card5;
    padding: 25px 30px 0 25px;
    @media screen and (max-width: 767px) {
      padding: 25px 25px 75px 25px;
      margin-top: -50px;
    }

    .switch {
      margin-bottom: 82px;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  .card.card5.card5__bg {
    background-image: url('../assets/image/cardBg_orang.png');
    background-size: cover;
    background-position: 50%;
    background-position-y: 0;
    animation: card1__bg 1s linear;
    background-repeat: no-repeat;

    .switch {
      border: 3px solid #fff;

      .switch_circle {
        animation: switch 1s linear;
        left: 50px;
        background: #fff;
      }
    }

    .card__wrapper {
      color: #fff;

      .card_title {
        color: #fff;
      }
    }
  }
}
.transformWrapper {
  width: fit-content;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .card.card5.card5__bg {
    background-image: none !important;
  }
  .card.card5 button {
    display: none;
  }
  .card.card5 {
    height: 320px;
    margin-top: 10px;
  }
  .card.card4.card4__bg {
    background-image: none !important;
  }
  .card.card4 {
    height: 320px;
    border-radius: 30px;
  }
  .card.card4 button {
    display: none;
  }
  .transformWrapper {
    width: 100%;
  }
  .cardWrapper {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .content {
    grid-template-columns: 1fr;
  }
}
